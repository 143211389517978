import React, { useEffect } from 'react';
import styled from "styled-components";
import { List, ListItem, ListItemText, ListSubheader, Checkbox, Grid, Typography, Divider } from '@mui/material';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import useSmallscreen from '../../../hooks/smallscreenHook';

import commonStyles from '../../../commonUtils/commonStyles';

import Main from '../../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.firmRed,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});


const SelectedIndicator = styled.div`
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  filter:  brightness(1);
  background-color:  ${(props) => props.color};
  border: ${(props) => props?.trackingEnabled ? '3.5px solid black' : ''}; 
`;


const COLORS = {
  0: {r: 169, g: 169, b: 169},
  1: {r: 255, g: 250, b: 200},
  2: {r: 220, g: 190, b: 255},
  3: {r: 128, g: 0, b: 0},
  4: {r: 250, g: 190, b: 212},
  5: {r: 67, g: 99, b: 216},
  6: {r: 170, g: 255, b: 195},
  7: {r: 154, g: 99, b: 36},
  8: {r: 255, g: 216, b:  177},
  9: {r: 240, g: 50, b: 230},
};

const componentToHex = (c) =>{
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}


const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}


export default function AnatomyMenuListComponent({ listWidth }) {
  const isSmallscreen = useSmallscreen();
  const [state, setState] = React.useState([]);

  useEffect(() => {
    const menuItems = Main.APP_PARAMS.globalState.menus3Dmodel.anatomyMenuItems;
    const newArray = [];
    //console.log('mIAna: ' + JSON.stringify(menuItems));
    if (menuItems) {
      var keyNames = Object.keys(menuItems);
      var keyValues = Object.values(menuItems);

      Object.entries(menuItems).forEach((item, index) => {
        //console.log('name: ' + keyNames[index]);
        //console.log('value: ' + keyValues[index]);
        const obj = { name: keyNames[index], visible: keyValues[index] };
        newArray.push(obj);
      });
    }
    setState(newArray);
    //console.log('arr: ' + JSON.stringify(newArray));
  }, [Main.APP_PARAMS.globalState.menus3Dmodel.anatomyMenuItems]);

  const handleToggle = (type, object, index) => () => {
    let stateArr = JSON.parse(JSON.stringify(state));
    //console.log('orig: ' + JSON.stringify(state));
    //console.log('Notorig: ' + JSON.stringify(stateArr));
    //object[type] = !object[type];
    object['visible'] = !object['visible'];
    stateArr[index] = object;
    setState(stateArr);
    //update in global state
    let globalMenuObj = JSON.parse(JSON.stringify(Main.APP_PARAMS.globalState.menus3Dmodel.anatomyMenuItems));
    let globalMeshesObj = JSON.parse(JSON.stringify(Main.APP_PARAMS.globalState.meshes3Dmodel));
    globalMenuObj[object.name] = object['visible'];
    globalMeshesObj[object.name] = object['visible'];
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      menus3Dmodel: {
        fibroidsMenuItems: { ...prevState.menus3Dmodel.fibroidsMenuItems },
        fibroidsMenuChangedItemName: '',
        anatomyMenuItems: globalMenuObj,
        anatomyMenuChangedItemName: object.name,
      },
      meshes3Dmodel: globalMeshesObj,
    }));
  };

  const DynamicListComponent = () => {
    if (state.length > 0) {
      return state.map((obj, index) => {
        //const labelId = `checkbox-list-label-${value}`;
        //console.log('obj: ' + JSON.stringify(obj.extracted));
        const color = COLORS[index % 10];
        return (
          <ListItem key={index} disablePadding>
            <Grid
              container
              direction={'row'}
              alignItems="center"
              justifyContent={'space-between'}
              style={{ maxWidth: listWidth, borderBottom: '1px solid white', height: '5%' }}
            >
              <Grid item style={{ minWidth: '23%', maxWidth: '23%', marginLeft: '16px' }}>
                {/*<ListItemText id={labelId} primary={`Line item Line item Line item ${value + 1}`} />*/}
                <Typography style={{ color: 'white', fontSize: isSmallscreen ? '10px' : '14px', marginRight: '5px' }}>
                  {obj.name.slice(0, -2)}
                </Typography>
              </Grid>

              <Grid item style={{ marginLeft: isSmallscreen ? '25px' : '35px'}}>
                <SelectedIndicator color={rgbToHex(color.r, color.g, color.b)} trackingEnabled={false} /> 
              </Grid>

              <Grid item style={{ marginRight: !isSmallscreen ? '14px' : '9px' }}>
                <Checkbox
                  color="secondary"
                  checked={obj.visible}
                  //tabIndex={-1}
                  disableRipple
                  //inputProps={{ 'aria-labelledby': labelId }}
                  onChange={handleToggle('visible', obj, index)}
                />
              </Grid>
            </Grid>
          </ListItem>
        );
      });
    } else {
      return null;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <List>
        <ListSubheader>
          <Grid container direction={'row'} justifyContent={'space-between'}>
          <Grid item>
              <Typography style={{ color: 'black', fontSize: isSmallscreen ? '10px' : '14px', marginRight: '5px' }}>
                Name
              </Typography>
            </Grid >
            <Grid item style={{ marginLeft: isSmallscreen ? '35px' : '40px' }}>
              <Typography style={{ color: 'black', fontSize: isSmallscreen ? '10px' : '14px'}}>
                Color
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'black', fontSize: isSmallscreen ? '10px' : '14px' }}>
                Visible
              </Typography>
            </Grid>
          </Grid>
        </ListSubheader>
        <DynamicListComponent />
      </List>
    </ThemeProvider>
  );
}
