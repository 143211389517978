import React, { useState, useEffect } from "react";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import HeaderComponent from "./Components/HeaderComponent";
import SingleModelViewComponent from "./Components/SIngleModelViewComponent";
import MultiModelViewComponent from "./Components/MultiModelViewComponent";
import FibroidsMenuComponent from "./Components/FibroidsMenuComponent";
import AnatomyMenuComponent from "./Components/AnatomyMenuComponent";

import commonStyles from "../../commonUtils/commonStyles";

import Main from "../../main/Main";

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function RenderComplex3DmodelScreen() {
  const [state, setState] = useState({
    modelURL: "",
    currentView: 1,
    anatomyMenuEnabled: false,
    fibroidsMenuEnabled: false,
  });

  const [headerHeight, setHeaderHeight] = useState(0)

  const [menuState, setMenuState] = React.useState([]);

  const [gltfFile, setGltfFile] = useState(null);
  const [trackingEnabled, setTrackingEnabled] = useState(false);

  useEffect(() => {
    const modelData = JSON.parse(localStorage.getItem("ModelData"));

    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      menus3Dmodel: {
        fibroidsMenuItems: { ...modelData.fibroidsMeshesObj },
        fibroidsMenuChangedItemName: "",
        anatomyMenuItems: { ...modelData.bodyMeshesObj },
        anatomyMenuChangedItemName: "",
      },
      meshes3Dmodel: { ...modelData.mergedMeshesObj },
      meshesLabels: {
        visible: false,
        positions: {
          Fibroid_1: [-0.1, 0.6, 0.3],
          Fibroid_3: [-0.3, -0.1, -0.1],
          Fibroid_4: [-0.3, -0.1, 0.1],
          Fibroid_5: [-0.1, 0, 0.2],
          Fibroid_6: [-0.2, 0, 0],
          Fibroid_2: [0, 0, -0.1],
        },
      },
    }));
    setState({ ...state, modelURL: modelData.modelURL });
  }, []);

  const RGB_COLORS = {
    0: { isColor: true, r: 230 / 255, g: 25 / 255, b: 75 / 255 },
    1: { isColor: true, r: 245 / 255, g: 130 / 255, b: 49 / 255 },
    2: { isColor: true, r: 255 / 255, g: 225 / 255, b: 25 / 255 },
    3: { isColor: true, r: 60 / 255, g: 180 / 255, b: 75 / 255 },
    4: { isColor: true, r: 66 / 255, g: 212 / 255, b: 244 / 255 },
    5: { isColor: true, r: 145 / 255, g: 30 / 255, b: 180 / 255 },
    6: { isColor: true, r: 70 / 255, g: 153 / 255, b: 144 / 255 },
    7: { isColor: true, r: 191 / 255, g: 239 / 255, b: 69 / 255 },
    8: { isColor: true, r: 0 / 255, g: 0 / 255, b: 117 / 255 },
    9: { isColor: true, r: 128 / 255, g: 128 / 255, b: 0 / 255 },
  };

  const ANATOMY_COLORS = {
    0: { isColor: true, r: 169 / 255, g: 169 / 255, b: 169 / 255 },
    1: { isColor: true, r: 255 / 255, g: 250 / 255, b: 200 / 255 },
    2: { isColor: true, r: 220 / 255, g: 190 / 255, b: 255 / 255 },
    3: { isColor: true, r: 128 / 255, g: 0, b: 0 },
    4: { isColor: true, r: 250 / 255, g: 190 / 255, b: 212 / 255 },
    5: { isColor: true, r: 67 / 255, g: 99 / 255, b: 216 / 255 },
    6: { isColor: true, r: 170 / 255, g: 255 / 255, b: 195 / 255 },
    7: { isColor: true, r: 154 / 255, g: 99 / 255, b: 36 / 255 },
    8: { isColor: true, r: 255 / 255, g: 216 / 255, b: 177 / 255 },
    9: { isColor: true, r: 240 / 255, g: 50 / 255, b: 230 / 255 },
  };

  useEffect(() => {
    if (!gltfFile) {
      return;
    }
    if (gltfFile.colorsUpdated) {
      return;
    }

    let COLORS = JSON.parse(localStorage.getItem("Colors"));

    const getColor = (key) => {
      const color = COLORS[key].split(",");
      const toReturn = {
        isColor: true,
        r: Number(color[0]) / 255,
        g: Number(color[1]) / 255,
        b: Number(color[2] / 255),
      };
      return toReturn;
    };

    const allKeys = Object.keys(
      Main?.APP_PARAMS?.globalState?.menus3Dmodel?.fibroidsMenuItems
    );

    allKeys.forEach((node, i) => {
      const file = gltfFile.nodes[node];
      if (file?.material) {
        file.material.color = COLORS ? getColor(node) : RGB_COLORS[i % 10];

        if (i === 0) {
          file.material.metalness = 0.2;
          file.material.roughness = 0.4;
        }
      }
    });

    const allAnatomyKeys = Object.keys(
      Main?.APP_PARAMS?.globalState?.menus3Dmodel?.anatomyMenuItems
    );

    allAnatomyKeys.forEach((node, i) => {
      const file = gltfFile.nodes[node];

      if (file?.material) {
        file.material.color = ANATOMY_COLORS[i % 10];
      }
    });

    gltfFile.colorsUpdated = true;
  }, [gltfFile]);

  const handleViewModeChange = (switchState) => {
    setState({ ...state, currentView: switchState ? 2 : 1 });
  };

  const handleTrackingChange = (switchState) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      meshesLabels: {
        visible: switchState,
        positions: { ...prevState.meshesLabels.positions },
      },
    }));
  };

  const handleMenuButtonPressed = (name) => {
    if (name === "Anatomy") {
      setState({ ...state, anatomyMenuEnabled: !state.anatomyMenuEnabled });
    }
    if (name === "Fibroids") {
      setState({ ...state, fibroidsMenuEnabled: !state.fibroidsMenuEnabled });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent
        setHeaderHeight={setHeaderHeight}
        viewerStateChanged={(multiviewerEnabled) =>
          handleViewModeChange(multiviewerEnabled)
        }
        initialViewMode={state.currentView}
        menuButtonPressed={(buttonName) => handleMenuButtonPressed(buttonName)}
        trackingStateChanged={(trackingEnabled) =>
          handleTrackingChange(trackingEnabled)
        }
        trackingEnabled={trackingEnabled}
        setTrackingEnabled={setTrackingEnabled}
      />
      <AnatomyMenuComponent showMenu={state.anatomyMenuEnabled} />
      <FibroidsMenuComponent
        showMenu={state.fibroidsMenuEnabled}
        gltfFile={gltfFile}
        trackingEnabled={trackingEnabled}
        setGltfFile={setGltfFile}
        setMenuState={setMenuState}
        menuState={menuState}
      />
      {state.currentView === 1 ? (
        <SingleModelViewComponent
          headerHeight={headerHeight}
          ModelURL={state.modelURL}
          setGltfFile={setGltfFile}
          trackingEnabled={trackingEnabled}
          gltfFile={gltfFile}
        />
      ) : (
        <MultiModelViewComponent ModelURL={state.modelURL} />
      )}
    </ThemeProvider>
  );
}
