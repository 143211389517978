import React, { useEffect, useRef } from 'react';
import Fab from "@mui/material/Fab";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { updateMeshes } from '../../../utils/updateRecord';

import { List, ListItem, ListItemText, ListSubheader, Checkbox, Grid, Typography, Divider } from '@mui/material';
import CustomSwitchComponent from '../../Components/CustomSwitchComponent/CustomSwitchComponent';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import styled from "styled-components";

import useSmallscreen from '../../../hooks/smallscreenHook';

import commonStyles from '../../../commonUtils/commonStyles';

import Main from '../../../main/Main';

const COLORS = {
  0: {r: 230, g: 25, b: 75},
  1: {r: 245, g: 130, b: 49},
  2: {r: 255, g: 225, b: 25},

  3: {r: 60, g: 180, b: 75},
  4: {r: 66, g: 212, b: 244},
  5: {r: 145, g: 30, b: 180},

  6: {r: 70, g: 153, b: 144},
  7: {r: 191, g: 239, b: 69},
  8: {r: 0, g: 0, b: 117},
  9: {r: 128, g: 128, b: 0},
}

const RGB_COLORS = {
  0: {isColor: true,  r: 230 , g: 25 , b: 75 },
  1: {isColor: true, r: 245 , g: 130 , b: 49 },
  2: {isColor: true, r: 255 , g: 225 , b: 25 },
  3: {isColor: true, r: 60 , g: 180 , b: 75 },
  4: {isColor: true, r: 66 , g: 212 , b: 244 },
  5: {isColor: true, r: 145 , g: 30 , b: 180 },
  6: {isColor: true, r: 70 , g: 153 , b: 144 },
  7: {isColor: true, r: 191 , g: 239 , b: 69 },
  8: {isColor: true, r: 0 , g: 0 , b: 117 },
  9: {isColor: true, r: 128 , g: 128 , b: 0 ,},
}


const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.firmRed,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const Indicator = styled.div`
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  background-color:  ${(props) => props.color};
`;

const SelectedIndicator = styled.div`
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  background-color:  ${(props) => props.color};
  border: ${(props) => props?.trackingEnabled ? '3.5px solid black' : ''}; 
`;

const StyledFab = styled.div`
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  background-color:  ${(props) => props.disabled ? 'blue' : 'white'};
  cursor: ${(props) => props.disabled ? '' : 'pointer'};
`;



export default function FibroidsMenuListComponent({ listWidth, gltfFile, trackingEnabled, setGltfFile, setMenuState, menuState }) {
  const isSmallscreen = useSmallscreen();
  const [selectedFibroid, setSelectedFibroid] = React.useState(null);

  const isFirst = useRef(true)

  const componentToHex = (c) =>{
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  
  const rgbToHex = (r, g, b) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  useEffect(() => {
    const menuItems = Main.APP_PARAMS.globalState.menus3Dmodel.fibroidsMenuItems;
    const newArray = [];
    if (menuItems) {
      var keyNames = Object.keys(menuItems);
      var keyValues = Object.values(menuItems);
          

      let tracked = false;
      
      Object.entries(menuItems).forEach((item, index) => {
        const getTracking = () => {
           if (tracked) {
             return false;
           }
           if (keyValues[index]) {
            tracked = true;
            return true;
           }
           return false;

        }
  
        //console.log('name: ' + keyNames[index]);
        //console.log('value: ' + keyValues[index]);
        const obj = { name: keyNames[index], visible: keyValues[index], extracted: !keyValues[index], tracking: trackingEnabled ? ((menuState && menuState.length > 0) ? getTracking (): (index === 0 ? true : false)) : false };
        newArray.push(obj);
      });
    }
    setMenuState(newArray);
    setSelectedFibroid(Object.keys(menuItems)[0]);
  }, []);

  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
      return;
    }
  
    if (!trackingEnabled) {
      return;
    }
    const menuItems = Main.APP_PARAMS.globalState.menus3Dmodel.fibroidsMenuItems;
    const newArray = [];
    let tracked = false;
    if (menuItems) {
      var keyNames = Object.keys(menuItems);
      var keyValues = Object.values(menuItems);
    

      Object.entries(menuItems).forEach((item, index) => {
        const getTracking = () => {
          if (!trackingEnabled) {
            return false;
          }
          if (tracked) {
            return false;
          }
          if (keyValues[index]) {
            tracked = true;
            return true;
          }
          return false;
          
        }
        const obj = { name: keyNames[index], visible: keyValues[index], extracted: !keyValues[index], tracking: getTracking() };
        newArray.push(obj);
      });
    }
    setMenuState(newArray);
    setSelectedFibroid(Object.keys(menuItems)[0]);
  }, [trackingEnabled]);


  const allAreUntracked = () => {
    let toReturn = true;
    for (let i = 0; i < menuState.length; i++) {
      if (menuState[i].tracking) {
        toReturn = false;
        break;
      }
    }
    return toReturn;
  };

  const handleToggle = (type, object, index) => () => {
    let stateArr = JSON.parse(JSON.stringify(menuState));
    if (type === 'extracted') {

      if (!trackingEnabled) {
        object['extracted'] = !object['extracted'];
        if (object['visible']) object['visible'] = !object['visible'];
        if (!object['extracted']) object['visible'] = !object['visible'];
      } else {
        if (!object['extracted'] && object['tracking'] === true ) {
          object['extracted'] = !object['extracted'];
          object['tracking'] = false;
          
          // const gltf = gltfFile;
          // const file = gltf.nodes[object?.name];
          // file.material.metalness = 1;
          // file.material.roughness = 1;

          let shouldStartFromFirst = true;
          for (let i = index + 1; i < stateArr.length; i++) {
             if (!stateArr[i].extracted) {
               shouldStartFromFirst = false;
               stateArr[i].tracking = true;
               const obj = stateArr[i];
              //  const fil = gltf.nodes[obj?.name];
              //  fil.material.metalness = 0.2;
              //  fil.material.roughness = 0.4;
               break;
             }
          }

          if (shouldStartFromFirst) {
            for (let i = 0; i < index; i++) {
              if (!stateArr[i].extracted) {
                stateArr[i].tracking = true;
                const obj = stateArr[i];
                // const fil = gltf.nodes[obj?.name];
                // fil.material.metalness = 0.2;
                // fil.material.roughness = 0.4;
                break;
              }
          }
        }
          // setGltfFile(gltf);
        } else {
          object['extracted'] = !object['extracted'];
          if (object['visible']) object['visible'] = !object['visible'];
          if (!object['extracted']) object['visible'] = !object['visible'];

          if (allAreUntracked()) {
            object['tracking'] = true; 
            // const gltf = gltfFile;
            // const fil = gltf.nodes[object?.name];
            // fil.material.metalness = 0.2;
            // fil.material.roughness = 0.4;
          }
    
        }

        if (object['visible']) object['visible'] = !object['visible'];
        if (!object['extracted']) object['visible'] = !object['visible'];
          
      }

    } else if (type === 'visible') {
      object['visible'] = !object['visible'];
    }


    stateArr[index] = object;
    setMenuState(stateArr);
    //update in global state
    let globalMenuObj = JSON.parse(JSON.stringify(Main.APP_PARAMS.globalState.menus3Dmodel.fibroidsMenuItems));
    let globalMeshesObj = JSON.parse(JSON.stringify(Main.APP_PARAMS.globalState.meshes3Dmodel));
    globalMenuObj[object.name] = object['visible'];
    globalMeshesObj[object.name] = object['visible'];
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      menus3Dmodel: {
        fibroidsMenuItems: globalMenuObj,
        fibroidsMenuChangedItemName: object.name,
        anatomyMenuItems: { ...prevState.menus3Dmodel.anatomyMenuItems },
        anatomyMenuChangedItemName: '',
      },
      meshes3Dmodel: globalMeshesObj,
    }));
  };


  const handleMoveUp = async (index) => {
    const patientData = JSON.parse(localStorage.getItem('PatientData'));
    const newState = [...menuState];
    const temp = newState[index - 1];
    newState[index - 1] =  newState[index];
    newState[index] =  temp;
    setMenuState(newState);
    const meshes = newState.map((obj) =>  obj.name);
    const newMeshes = meshes.join('&');
    console.log(newMeshes, 'newMeshes')

    let globalMenuObj = JSON.parse(JSON.stringify(Main.APP_PARAMS.globalState.menus3Dmodel.fibroidsMenuItems));
    const newObj = {};

    meshes.forEach((mesh) => {
       newObj[mesh] = globalMenuObj[mesh];
    })

    console.log(newObj, 'newObj')

    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      menus3Dmodel: {
        ...prevState.menus3Dmodel,
        fibroidsMenuItems: newObj,
      },
    }));

    const modelData = JSON.parse(localStorage.getItem('ModelData'));

    const newData = {
      ...modelData,
      fibroidsMeshesObj: newObj,
    }
    
    localStorage.setItem('ModelData', JSON.stringify(newData));

    const res = await updateMeshes('patients', patientData?.patientId, newMeshes)


  }

  const handleMoveDown = async (index) => {
    const patientData = JSON.parse(localStorage.getItem('PatientData'));
    const newState = [...menuState];
    const temp = newState[index + 1];
    newState[index + 1] =  newState[index];
    newState[index] =  temp;
    setMenuState(newState);
    const meshes = newState.map((obj) =>  obj.name);
    const newMeshes = meshes.join('&');

    let globalMenuObj = JSON.parse(JSON.stringify(Main.APP_PARAMS.globalState.menus3Dmodel.fibroidsMenuItems));
    const newObj = {};

    meshes.forEach((mesh) => {
       newObj[mesh] = globalMenuObj[mesh];
    })


    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      menus3Dmodel: {
        ...prevState.menus3Dmodel,
        fibroidsMenuItems: newObj,
      },
    }));

    const modelData = JSON.parse(localStorage.getItem('ModelData'));

    const newData = {
      ...modelData,
      fibroidsMeshesObj: newObj,
    }
    
    localStorage.setItem('ModelData', JSON.stringify(newData));

    const res = await updateMeshes('patients', patientData?.patientId, newMeshes)
  }


  let COLORS = JSON.parse(localStorage.getItem('Colors'));
  let globalMenuObj = JSON.parse(JSON.stringify(Main.APP_PARAMS.globalState.menus3Dmodel.fibroidsMenuItems));
   
  const getColor = (key) => {
    const color = COLORS[key].split(',');
    const toReturn = {isColor: true, r: Number(color[0]), g: Number(color[1]), b: Number(color[2])}
    return toReturn
  }


  const DynamicListComponent = () => {
    if (menuState.length > 0) {
      return menuState.map((obj, index) => { 
        const color = COLORS ? getColor(Object.keys(globalMenuObj)[index]) : RGB_COLORS[index % 10];
        return (
          <ListItem key={index} disablePadding>
            <Grid
              container
              direction={'row'}
              alignItems="center"
              justifyContent={'space-between'}
              style={{ maxWidth: listWidth, borderBottom: '1px solid white', height: '5%' }}
            >
              <Grid item style={{ marginLeft: isSmallscreen ? '-5px' : '0px'}}>
                {/*<ListItemText id={labelId} primary={`Line item Line item Line item ${value + 1}`} />*/}
                <Typography style={{ color: 'white', fontSize: isSmallscreen ? '10px' : '14px', marginLeft: '30px' }}>
                  {index + 1}
                </Typography>
              </Grid>

              <Grid item style={{ marginLeft: '16px'}}>
                {
                  !trackingEnabled && <SelectedIndicator color={rgbToHex(color.r, color.g, color.b)} trackingEnabled={false} /> 
                }
                {
                  trackingEnabled && (
                  obj.tracking ? <SelectedIndicator color={rgbToHex(color.r, color.g, color.b)} trackingEnabled={obj.tracking } /> :  <Indicator color={rgbToHex(color.r, color.g, color.b)} />)
                }
              </Grid>

              <Grid item style={{ marginRight: (trackingEnabled && obj.tracking) ? '-2.5px': '-6px'}}>
                <Checkbox
                  color="secondary"
                  checked={obj.visible}
                  //tabIndex={-1}
                  disableRipple
                  //inputProps={{ 'aria-labelledby': labelId }}
                  onChange={handleToggle('visible', obj, index)}
                />
              </Grid>
          
              <Grid item style={{ marginRight: isSmallscreen ? '-15px' : '-20px'}}>
                <Checkbox
                  color="secondary"
                  checked={obj.extracted}
                  //tabIndex={-1}
                  disableRipple
                  //inputProps={{ 'aria-labelledby': labelId }}
                  onChange={handleToggle('extracted', obj, index)}
                />
              </Grid>

              <Grid item style={{ marginRight: isSmallscreen ? '0px' : '10px'}}>
                {/* <StyledFab disabled={index === menuState.length - 1} onClick={() => handleMoveDown(index)}  size="small"> */}
                  <KeyboardArrowDownIcon color={index === menuState.length - 1  ? 'action' : 'disabled'}   onClick={() =>  index === menuState.length - 1 ? () => {} : handleMoveDown(index)} />
                {/* </StyledFab> */}
                {/* <StyledFab disabled={index === 0} onClick={() => handleMoveUp(index)} size="small"  aria-label="like"> */}
                  <KeyboardArrowUpIcon color={index === 0 ? 'action' : 'disabled'} onClick={() => index === 0 ? () => {} : handleMoveUp(index)}  sx={{ mr: 1 }} />
                {/* </StyledFab> */}
                </Grid>
            </Grid>
          </ListItem>
        );
      });
    } else {
      return null;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <List>
        <ListSubheader>
          <Grid container direction={'row'} justifyContent={'space-between'}>
          <Grid item>
              <Typography style={{ color: 'black', fontSize: isSmallscreen ? '10px' : '14px', marginRight: '5px' }}>
                Order
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'black', fontSize: isSmallscreen ? '10px' : '14px', marginRight: '5px' }}>
                  Tracking
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'black', fontSize: isSmallscreen ? '10px' : '14px', marginRight: '5px' }}>
                Visible
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'black', fontSize: isSmallscreen ? '10px' : '14px', marginRight: '5px' }}>
                Extracted
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'black', fontSize: isSmallscreen ? '10px' : '14px', marginRight: '5px' }}>
                Move
              </Typography>
            </Grid>
          </Grid>
        </ListSubheader>
        <DynamicListComponent />
      </List>
    </ThemeProvider>
  );
}
