import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 17,
  padding: 3,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    //transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      //transform: 'translateX(30px)',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 10 / 2,
  },
}));

export default MaterialUISwitch;
