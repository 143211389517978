var singleton = new (function () {
  /*
      this.publicTestVar = {
        prop1: 'test',
      };
      var privateTestVar = null;
    
      this.init = () => {
        privateTestVar = 'test private';
      };
    
      this.getTestVar = () => {
        return privateTestVar;
      };
    
      this.setTestVar = (val) => {
        privateTestVar = val;
      };
      */
  /*
      this.getfetchBaseURL = () => {
        return 'https://cors-anywhere.herokuapp.com/' + 'http://18.191.31.212:3000';
      };
      */
  //require('dotenv').config();
  //current baseurls.
  //this.BASE_URL = 'https://970d-34-228-185-247.ngrok.io';
  //this.BASE_URL = 'https://corsanywhere.herokuapp.com/http://3.220.180.144';
  //this.BASE_URL = 'https://api.allorigins.win/raw?url=http://44.206.38.138';
  this.BASE_URL = "https://smarthermri-backend.com";
  //this.BASE_URL = 'http://localhost:3000';
  this.APP_MODE = "normal"; //normal or maintenance

  this.APP_PARAMS = {
    userRole: "",
    setGlobalState: (state) => {},
    globalState: {
      applicationMode: "",
      toastOpen: false,
      toastAutohideDuration: 15000,
      toastStyle: "success",
      toastMessage: "",
      globalLoading: false,
      menus3Dmodel: {
        fibroidsMenuItems: {},
        fibroidsMenuChangedItemName: "",
        anatomyMenuItems: {},
        anatomyMenuChangedItemName: "",
      },
      meshes3Dmodel: {},
      meshesLabels: {},
      gltf: null,
    },
  };

  var screenNames = ["Home", "Test backend connection"];

  this.getScreenNames = () => {
    return screenNames;
  };

  var commonScreenSettings = {
    headerHeightBigScreen: "93px",
    headerHeightSmallScreen: "120px",
  };

  this.getCommonScreenSettings = () => {
    return commonScreenSettings;
  };

  this.setCommonScreenSettings = (data) => {
    commonScreenSettings = data;
  };

  this.anatomyMenuEnabled = false;

  //Init method
  this.init = () => {};
})();

export default singleton;
