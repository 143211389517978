import React, { Suspense, useState, useEffect, useRef } from 'react';

import { Grid, Container, Typography, Button, Switch } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

//import { ROUTES_LIST } from '../../commonUtils/constants';

import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { OrbitControls, Html, useProgress } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
//import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
//do not forget to move .glb into /public !!!
//import Model from '../../assets/3D_models/fibroid_tumor_case_2_rotated_upright/Fibroid_tumor_case_2_rotated_upright_JSX3D';
//import Model from '../../assets/3D_models/fibroid_tumor_case_2_rotated_upright/Fibroid_tumor_case_2_modified_JSX3D';

import useSmallscreen from '../../hooks/smallscreenHook';
//import SingleModelViewComponent from './Components/SIngleModelViewComponent';

import commonStyles from '../../commonUtils/commonStyles';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

function Loader() {
  const { active, progress, errors, item, loaded, total } = useProgress();
  return (
    <Html center>
      <Typography style={{ color: 'white' }}>{Math.round(progress)} % loaded</Typography>
    </Html>
  );
}

const Model = () => {
  const modelURL = JSON.parse(localStorage.getItem('testModelURL'));
  const gltf = useLoader(
    GLTFLoader,
    modelURL,
    //'https://smarthermri-storage.s3.amazonaws.com/3Dmodels/3Dmodel_test.gltf',
    //'https://smarthermri-storage.s3.amazonaws.com/3Dmodels/testModel3.gltf',
    //"https://smarthermri-storage.s3.amazonaws.com/3Dmodels/3Dmodel_test.gltf"
    //"https://smarthermri-storage.s3.amazonaws.com/3Dmodels/ladybug.gltf"
    //"https://smarthermri-storage.s3.amazonaws.com/3Dmodels/scene.gltf"
    //"https://r105.threejsfundamentals.org/threejs/resources/models/cartoon_lowpoly_small_city_free_pack/scene.gltf"
    (loader) => {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/');
      loader.setDRACOLoader(dracoLoader);
    }
  );
  return <primitive object={gltf.scene} />;
};

const viewButtonAreaHeight = '40px';

export default function ModelLoadingTestScreen() {
  const OrbitControlsRef = useRef();
  const isSmallscreen = useSmallscreen();
  const [state, setState] = useState({
    meshes_names: [],
    sideGridXS: 4,
    centralGridXS: 4,
    anatomyMenuEnabled: false,
    fibroidsMenuEnabled: false,
    singleViewHeight:
      window.innerHeight -
      parseInt(Main.getCommonScreenSettings().headerHeightBigScreen) -
      parseInt(viewButtonAreaHeight) +
      'px',
  });

  useEffect(() => {
    if (isSmallscreen) {
      setState((prevState) => ({
        ...prevState,
        sideGridXS: 0,
        centralGridXS: 12,
        singleViewHeight:
          window.innerHeight -
          parseInt(Main.getCommonScreenSettings().headerHeightSmallScreen) -
          parseInt(viewButtonAreaHeight) +
          'px',
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        sideGridXS: 3,
        centralGridXS: 6,
        singleViewHeight:
          window.innerHeight -
          parseInt(Main.getCommonScreenSettings().headerHeightBigScreen) -
          parseInt(viewButtonAreaHeight) +
          'px',
      }));
    }
  }, [isSmallscreen]);

  function ControlVisibility() {
    //console.log('ControlVisibility');
    const { scene } = useThree();
    const meshes_names_array = [];
    useEffect(() => {
      scene.children.map((obj) => {
        obj.traverse((child) => {
          meshes_names_array.push(child.name);
          //console.log('TRA: ' + JSON.stringify(child.name));
          //if (child.name === 'mesh_0') child.visible = false;
          //if (child.name === 'mesh_0_1') child.visible = false;
          //if (child.name === 'mesh_0_2') child.visible = false;
          //if (child.name === 'mesh_0_3') child.visible = false;
          //if (child.name === 'mesh_0_4') child.visible = false;
        });
      });
      setState((prevState) => ({
        ...prevState,
        meshes_names: meshes_names_array,
      }));
    });
    /*
    useEffect(() => {
      Object.entries(Main.APP_PARAMS.globalState.meshes3Dmodel).map((item) => {
        //console.log(item[1]);
        scene.children.map((obj) => {
          obj.traverse((child) => {
            console.log('TRA: ' + JSON.stringify(child.name));
            if (child.name === item[0]) {
              child.visible = item[1];
              //console.log(child.name + ':' + JSON.stringify(child.position));
            }
          });
        });
      });
      //camera.updateProjectionMatrix();
    }, []);
    */

    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid container style={{ background: commonStyles.color.black }}>
          <Grid item xs={state.sideGridXS} />
          <Grid
            item
            xs={state.centralGridXS}
            align="center"
            style={{
              maxHeight: viewButtonAreaHeight,
              minHeight: viewButtonAreaHeight,
            }}
          >
            {/*<Grid
              container
              direction={'row'}
              justifyContent={'space-between'}
              alignItems="center"
              style={getStyles().topLevelContainer}
            >
              <Button
                style={{ ...getStyles().button, ...{ fontSize: isSmallscreen ? '10px' : '14px' } }}
                variant="contained"
                onClick={() => handleViewButtonPress('Coronal')}
              >
                Coronal
              </Button>
              <Button
                style={{ ...getStyles().button, ...{ fontSize: isSmallscreen ? '10px' : '14px' } }}
                variant="contained"
                onClick={() => handleViewButtonPress('Axial')}
              >
                Axial
              </Button>
              <Button
                style={{ ...getStyles().button, ...{ fontSize: isSmallscreen ? '10px' : '14px' } }}
                variant="contained"
                onClick={() => handleViewButtonPress('Sagittal')}
              >
                Sagittal
              </Button>
              <Button
                style={{ ...getStyles().button, ...{ fontSize: isSmallscreen ? '10px' : '14px' } }}
                variant="contained"
                onClick={() => handleViewButtonPress('Trendelenburg')}
              >
                Trendelenburg
              </Button>
        </Grid>*/}
            <Grid item xs={state.sideGridXS} />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          //style={{ border: '1px solid', borderColor: 'orange', maxHeight: '400px' }}
          //style={{ maxHeight: '420px' }}
        >
          <Canvas
            style={{
              ...getStyles().mainPaper,
              ...{
                height: state.singleViewHeight, //'380px',
              },
            }}
            camera={{
              //position: singleModeViewState.coronal.initialCameraPosition,
              zoom: 1,
            }}
          >
            {/*<ambientLight intensity={0.2} />*/}
            <spotLight intensity={0.5} penumbra={1} position={[-10, 10, 10]} />
            <spotLight intensity={0.5} penumbra={1} position={[-10, -10, -10]} />
            <spotLight intensity={0.5} penumbra={1} position={[10, -10, 10]} />
            <spotLight intensity={0.5} penumbra={1} position={[10, 10, -10]} />
            <Suspense fallback={<Loader />}>
              <Model />
              <ControlVisibility />
            </Suspense>
            <OrbitControls
              ref={OrbitControlsRef}
              //makeDefault
              //camera={CustCamera}
              //minPolarAngle={0}
              //maxPolarAngle={Math.PI}
              //minAzimuthAngle={-Infinity}
              //maxAzimuthAngle={Infinity}
              //enableZoom={false}
              enablePan={false}
            />
          </Canvas>
          <Grid
            container
            style={{
              position: 'absolute',
              background: 'rgba(200,200,444, 0.5)',
              //width: '30%',
              zIndex: '10000',
            }}
          >
            {state.meshes_names.map((item, index) => {
              return (
                <Typography key={index} style={{ marginRight: '10px' }}>
                  MeshName: {item}
                </Typography>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    //mainPaper: { background: commonStyles.color.black, width: '100vw', height: 'calc(100vh - 70px)' },
    mainPaper: { background: commonStyles.color.black, width: '100vw' },

    topLevelContainer: {
      background: commonStyles.color.black,
      height: '100%',
      paddingLeft: '3px',
      paddingRight: '3px',
    },
    button: {
      maxWidth: '200px',
      //margin: '10px',
      //marginLeft: '30px',
    },
  };
  return styles;
};
