import React, { Suspense, useState, useEffect, useRef } from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Canvas, useThree, useLoader } from '@react-three/fiber';
import { OrbitControls, Html, useProgress } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

import useSmallscreen from '../../../hooks/smallscreenHook';

import commonStyles from '../../../commonUtils/commonStyles';

import Main from '../../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

function Loader() {
  const { active, progress, errors, item, loaded, total } = useProgress();
  return (
    <Html center>
      <Typography style={{ color: 'white' }}>{Math.round(progress)} % loaded</Typography>
    </Html>
  );
}

const Model = ({ model_url }) => {
  const gltf = useLoader(
    GLTFLoader,
    model_url,
    //'https://smarthermri-storage.s3.amazonaws.com/3Dmodels/testModel2.gltf',
    //'https://smarthermri-storage.s3.amazonaws.com/3Dmodels/3Dmodel_test.gltf'
    //'https://smarthermri-storage.s3.amazonaws.com/3Dmodels/ladybug.gltf'
    //"https://smarthermri-storage.s3.amazonaws.com/3Dmodels/scene.gltf"
    //"https://r105.threejsfundamentals.org/threejs/resources/models/cartoon_lowpoly_small_city_free_pack/scene.gltf"
    (loader) => {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/');
      loader.setDRACOLoader(dracoLoader);
    }
  );
  return <primitive object={gltf.scene.clone(true)} />;
};

const viewButtonAreaHeight = '40px';

export default function MultiModelViewComponent({ ModelURL }) {
  const isSmallscreen = useSmallscreen();

  const [multiModeViewState, setMultiModeViewState] = useState({
    moveCameraAxial: false,
    moveCameraCoronal: false,
    moveCameraSagittal: false,
    moveCameraTrendelenburg: false,
    initialCameraZoomSmallScreen: 12,
    initialCameraZoomBigScreen: 12,
    axial: {
      initialCameraPosition: [0, -5, 0],
    },
    coronal: {
      initialCameraPosition: [-1, 0, 5],
    },
    sagittal: {
      initialCameraPosition: [5, 0, 0],
    },
    trendelenburg: {
      initialCameraPosition: [-0.4, -3.95, 3.2],
    },
    repositionCoordinatesAxial: [-1, -5, 5],
    repositionCoordinatesCoronal: [-1, -5, 5],
    repositionCoordinatesSagittal: [-1, -5, 5],
    repositionCoordinatesTrendelenburg: [-1, -5, 5],
    singleViewHeight:
      (window.innerHeight - parseInt(Main.getCommonScreenSettings().headerHeightBigScreen)) / 2 -
      parseInt(viewButtonAreaHeight) -
      4 +
      'px',
  });

  useEffect(() => {
    if (isSmallscreen) {
      //console.log('small screen');
      setMultiModeViewState((prevState) => ({
        ...prevState,
        singleViewHeight:
          (window.innerHeight - parseInt(Main.getCommonScreenSettings().headerHeightSmallScreen)) / 2 -
          parseInt(viewButtonAreaHeight) -
          4 +
          'px',
      }));
    } else {
      //console.log('big screen');
      setMultiModeViewState((prevState) => ({
        ...prevState,
        singleViewHeight:
          (window.innerHeight - parseInt(Main.getCommonScreenSettings().headerHeightBigScreen)) / 2 -
          parseInt(viewButtonAreaHeight) -
          4 +
          'px',
      }));
    }
  }, [isSmallscreen]);

  const orbitControlsAxialRef = useRef(null);
  const orbitControlsCoronalRef = useRef(null);
  const orbitControlsSagittalRef = useRef(null);
  const orbitControlsTrendelenburgRef = useRef(null);

  const handleViewButtonPress = (name) => {
    //console.log(name);
    if (name === 'Axial') {
      //orbitControlsAxialRef.current.reset();
      setMultiModeViewState((prevState) => ({
        ...prevState,
        repositionCoordinatesAxial: multiModeViewState.axial.initialCameraPosition,
        moveCameraAxial: true,
      }));
    }
    if (name === 'Coronal') {
      //orbitControlsCoronalRef.current.reset();
      setMultiModeViewState((prevState) => ({
        ...prevState,
        repositionCoordinatesCoronal: multiModeViewState.coronal.initialCameraPosition,
        moveCameraCoronal: true,
      }));
    }
    if (name === 'Sagittal') {
      //orbitControlsSagittalRef.current.reset();
      setMultiModeViewState((prevState) => ({
        ...prevState,
        repositionCoordinatesSagittal: multiModeViewState.sagittal.initialCameraPosition,
        moveCameraSagittal: true,
      }));
    }
    if (name === 'Trendelenburg') {
      //orbitControlsTrendelenburgRef.current.reset();
      setMultiModeViewState((prevState) => ({
        ...prevState,
        repositionCoordinatesTrendelenburg: multiModeViewState.trendelenburg.initialCameraPosition,
        moveCameraTrendelenburg: true,
      }));
    }
  };

  //console.log(window.innerWidth);
  //console.log(window.innerHeight);

  function ControlVisibility() {
    const { scene } = useThree();

    useEffect(() => {
      Object.entries(Main.APP_PARAMS.globalState.meshes3Dmodel).map((item) => {
        //console.log(item[1]);
        scene.children.map((obj) => {
          obj.traverse((child) => {
            //console.log('TRA: ' + JSON.stringify(child.name));
            if (child.name === item[0]) child.visible = item[1];
          });
        });
      });
    }, []);

    return null;
  }

  function MoveCamera({ cameraName, repCoords }) {
    const { camera, scene } = useThree();

    const newX = repCoords[0];
    const newY = repCoords[1];
    const newZ = repCoords[2];
    let currentCameraDistance;
    if (cameraName === 'Axial')
      currentCameraDistance = camera.position.distanceTo(orbitControlsAxialRef.current.target);
    if (cameraName === 'Coronal')
      currentCameraDistance = camera.position.distanceTo(orbitControlsCoronalRef.current.target);
    if (cameraName === 'Sagittal')
      currentCameraDistance = camera.position.distanceTo(orbitControlsSagittalRef.current.target);
    if (cameraName === 'Trendelenburg')
      currentCameraDistance = camera.position.distanceTo(orbitControlsTrendelenburgRef.current.target);
    useEffect(() => {
      camera.position.set(newX, newY, newZ);
      camera.position.setLength(currentCameraDistance);
      camera.updateProjectionMatrix();
      //camera.position.lerp(vec.set(-1, -5, 5), 0.5);
    }, []);
    if (cameraName === 'Axial') {
      setMultiModeViewState((prevState) => ({
        ...prevState,
        moveCameraAxial: false,
      }));
    }
    if (cameraName === 'Coronal') {
      setMultiModeViewState((prevState) => ({
        ...prevState,
        moveCameraCoronal: false,
      }));
    }
    if (cameraName === 'Sagittal') {
      setMultiModeViewState((prevState) => ({
        ...prevState,
        moveCameraSagittal: false,
      }));
    }
    if (cameraName === 'Trendelenburg') {
      setMultiModeViewState((prevState) => ({
        ...prevState,
        moveCameraTrendelenburg: false,
      }));
    }

    //console.log('Camera position. X:' + camera.position.x + ' Y:' + camera.position.y + ' Z:' + camera.position.z);
    //console.log('zoom (distanceTo): ' + camera.position.distanceTo(OrbitControlsRef.current.target));

    //camera.position.distanceTo(OrbitControlsRef.current.target);
    //const zoomLvl = OrbitControlsRef.getDistance();
    //console.log('zoom: ' + camera.position.distanceTo(OrbitControlsRef.current.target));
    //}
    return null;
  }

  function Labels() {
    const [state, setState] = useState(null);

    useEffect(() => {
      setState(Main.APP_PARAMS.globalState.meshesLabels);
    }, [Main.APP_PARAMS.globalState.meshesLabels]);

    if (state && state.visible) {
      /*
      return (
        <React.Fragment>
          {Object.entries(state.positions).map((item, index) => {
            return (
              <Html
                key={index}
                position={item[1]}
                center
                style={
                  Main.APP_PARAMS.globalState.meshes3Dmodel[item[0]]
                    ? {
                        opacity: 1,
                      }
                    : {
                        opacity: 0,
                      }
                }
              >
                <Typography style={{ color: 'White' }}>{item[0]}</Typography>
              </Html>
            );
          })}
        </React.Fragment>
      );
      */
      return null;
    } else {
      return null;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box style={getStyles().mainPaper}>
        <Grid
          container
          //style={{ border: '1px solid', borderColor: 'cyan' }}
        >
          <Grid container>
            <Grid
              item
              xs={6}
              align="center"
              style={{ borderRight: '2px solid white', borderBottom: '2px solid white' }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  align="center"
                  style={{ maxHeight: viewButtonAreaHeight, minHeight: viewButtonAreaHeight }}
                >
                  <Button
                    style={{ ...getStyles().button, ...{ fontSize: isSmallscreen ? '10px' : '14px' } }}
                    variant="contained"
                    onClick={() => handleViewButtonPress('Axial')}
                  >
                    Axial
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  //style={{ border: '1px solid', borderColor: 'orange', maxHeight: '400px' }}
                  //style={{ maxHeight: '420px' }}
                >
                  <Canvas
                    style={{
                      background: 'black',
                      //border: '1px solid',
                      //borderColor: 'white',
                      height: multiModeViewState.singleViewHeight, //'380px',
                    }}
                    camera={{
                      position: multiModeViewState.axial.initialCameraPosition,
                      zoom: isSmallscreen
                        ? multiModeViewState.initialCameraZoomSmallScreen
                        : multiModeViewState.initialCameraZoomBigScreen,
                    }}
                  >
                    <ControlVisibility />
                    <Labels />
                    {multiModeViewState.moveCameraAxial ? (
                      <MoveCamera cameraName="Axial" repCoords={multiModeViewState.repositionCoordinatesAxial} />
                    ) : null}
                    <ambientLight intensity={0.2} />
                    <spotLight intensity={0.5} penumbra={1} position={[-10, 10, 10]} />
                    <spotLight intensity={0.5} penumbra={1} position={[-10, -10, -10]} />
                    <spotLight intensity={0.5} penumbra={1} position={[10, -10, 10]} />
                    <spotLight intensity={0.5} penumbra={1} position={[10, 10, -10]} />
                    <Suspense fallback={<Loader />}>
                      <Model model_url={ModelURL} />
                    </Suspense>
                    <OrbitControls ref={orbitControlsAxialRef} target={[0, 0, 0]} enablePan={false} />
                  </Canvas>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} align="center" style={{ borderLeft: '2px solid white', borderBottom: '2px solid white' }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  align="center"
                  style={{ maxHeight: viewButtonAreaHeight, minHeight: viewButtonAreaHeight }}
                >
                  <Button
                    style={{ ...getStyles().button, ...{ fontSize: isSmallscreen ? '10px' : '14px' } }}
                    variant="contained"
                    onClick={() => handleViewButtonPress('Coronal')}
                  >
                    Coronal
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  //style={{ border: '1px solid', borderColor: 'orange', maxHeight: '400px' }}
                  //style={{ maxHeight: '400px' }}
                >
                  <Canvas
                    style={{
                      background: 'black',
                      //border: '1px solid',
                      //borderColor: 'white',
                      height: multiModeViewState.singleViewHeight, //'380px',
                    }}
                    camera={{
                      position: multiModeViewState.coronal.initialCameraPosition,
                      zoom: isSmallscreen
                        ? multiModeViewState.initialCameraZoomSmallScreen
                        : multiModeViewState.initialCameraZoomBigScreen,
                    }}
                  >
                    <ControlVisibility />
                    <Labels />
                    {multiModeViewState.moveCameraCoronal ? (
                      <MoveCamera cameraName="Coronal" repCoords={multiModeViewState.repositionCoordinatesCoronal} />
                    ) : null}
                    <ambientLight intensity={0.2} />
                    <spotLight intensity={0.5} penumbra={1} position={[-10, 10, 10]} />
                    <spotLight intensity={0.5} penumbra={1} position={[-10, -10, -10]} />
                    <spotLight intensity={0.5} penumbra={1} position={[10, -10, 10]} />
                    <spotLight intensity={0.5} penumbra={1} position={[10, 10, -10]} />
                    <Suspense fallback={<Loader />}>
                      <Model model_url={ModelURL} />
                    </Suspense>
                    <OrbitControls ref={orbitControlsCoronalRef} target={[0, 0, 0]} enablePan={false} />
                  </Canvas>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} align="center" style={{ borderTop: '2px solid white', borderRight: '2px solid white' }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  align="center"
                  style={{ maxHeight: viewButtonAreaHeight, minHeight: viewButtonAreaHeight }}
                >
                  <Button
                    style={{ ...getStyles().button, ...{ fontSize: isSmallscreen ? '10px' : '14px' } }}
                    variant="contained"
                    onClick={() => handleViewButtonPress('Sagittal')}
                  >
                    Sagittal
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  //style={{ border: '1px solid', borderColor: 'orange', maxHeight: '400px' }}
                  //style={{ maxHeight: '400px' }}
                >
                  <Canvas
                    style={{
                      background: 'black',
                      //border: '1px solid',
                      //borderColor: 'white',
                      height: multiModeViewState.singleViewHeight, //'380px',
                    }}
                    camera={{
                      position: multiModeViewState.sagittal.initialCameraPosition,
                      zoom: isSmallscreen
                        ? multiModeViewState.initialCameraZoomSmallScreen
                        : multiModeViewState.initialCameraZoomBigScreen,
                    }}
                  >
                    <ControlVisibility />
                    <Labels />
                    {multiModeViewState.moveCameraSagittal ? (
                      <MoveCamera cameraName="Sagittal" repCoords={multiModeViewState.repositionCoordinatesSagittal} />
                    ) : null}
                    <ambientLight intensity={0.2} />
                    <spotLight intensity={0.5} penumbra={1} position={[-10, 10, 10]} />
                    <spotLight intensity={0.5} penumbra={1} position={[-10, -10, -10]} />
                    <spotLight intensity={0.5} penumbra={1} position={[10, -10, 10]} />
                    <spotLight intensity={0.5} penumbra={1} position={[10, 10, -10]} />
                    <Suspense fallback={<Loader />}>
                      <Model model_url={ModelURL} />
                    </Suspense>
                    <OrbitControls ref={orbitControlsSagittalRef} target={[0, 0, 0]} enablePan={false} />
                  </Canvas>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} align="center" style={{ borderTop: '2px solid white', borderLeft: '2px solid white' }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  align="center"
                  style={{ maxHeight: viewButtonAreaHeight, minHeight: viewButtonAreaHeight }}
                >
                  <Button
                    style={{ ...getStyles().button, ...{ fontSize: isSmallscreen ? '10px' : '14px' } }}
                    variant="contained"
                    onClick={() => handleViewButtonPress('Trendelenburg')}
                  >
                    Trendelenburg
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  //style={{ border: '1px solid', borderColor: 'orange', maxHeight: '400px' }}
                  //style={{ maxHeight: '400px' }}
                >
                  <Canvas
                    style={{
                      background: 'black',
                      //border: '1px solid',
                      //borderColor: 'white',
                      height: multiModeViewState.singleViewHeight, //'380px',
                    }}
                    camera={{
                      position: multiModeViewState.trendelenburg.initialCameraPosition,
                      zoom: isSmallscreen
                        ? multiModeViewState.initialCameraZoomSmallScreen
                        : multiModeViewState.initialCameraZoomBigScreen,
                    }}
                  >
                    <ControlVisibility />
                    <Labels />
                    {multiModeViewState.moveCameraTrendelenburg ? (
                      <MoveCamera
                        cameraName="Trendelenburg"
                        repCoords={multiModeViewState.repositionCoordinatesTrendelenburg}
                      />
                    ) : null}
                    <ambientLight intensity={0.2} />
                    <spotLight intensity={0.5} penumbra={1} position={[-10, 10, 10]} />
                    <spotLight intensity={0.5} penumbra={1} position={[-10, -10, -10]} />
                    <spotLight intensity={0.5} penumbra={1} position={[10, -10, 10]} />
                    <spotLight intensity={0.5} penumbra={1} position={[10, 10, -10]} />
                    <Suspense fallback={<Loader />}>
                      <Model model_url={ModelURL} />
                    </Suspense>
                    <OrbitControls ref={orbitControlsTrendelenburgRef} target={[0, 0, 0]} enablePan={false} />
                  </Canvas>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: { background: commonStyles.color.black, width: '100%', height: '100%' },

    button: {
      maxWidth: '200px',
      maxHeight: '30px',
      margin: '10px',
      //marginLeft: '30px',
    },
  };
  return styles;
};
