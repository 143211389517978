import React, { Suspense, useState, useEffect, useRef } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

import { Canvas, useThree, useLoader } from "@react-three/fiber";
import { OrbitControls, Html, useProgress } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

import useSmallscreen from "../../../hooks/smallscreenHook";

import commonStyles from "../../../commonUtils/commonStyles";

import Main from "../../../main/Main";

const fruitFilter = {};

const Model = ({ model_url, setGltfFile, trackingEnabled }) => {
  const gltf = useLoader(GLTFLoader, model_url, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://raw.githubusercontent.com/smartherinc/threejs_public/main/draco/"
    );
    loader.setDRACOLoader(dracoLoader);
  });

  const allAnatomyKeys = Object.keys(
    Main?.APP_PARAMS?.globalState?.menus3Dmodel?.anatomyMenuItems
  );
  const modelURL = JSON.parse(localStorage.getItem("ModelData"));
  if (
    modelURL.modelURL ===
    "https://smarthermri-storage.s3.amazonaws.com/3Dmodels/myFruiterusDemo2/Untitled.gltf"
  ) {
    gltf.scene.scale.set(0.03, 0.03, 0.03);
    setGltfFile(gltf);
  } else {
    allAnatomyKeys.forEach((node, i) => {
      const file = gltf.nodes[node];
      if (file?.material) {
        file.material.color = ANATOMY_COLORS[i % 10];
      }
    });
    setGltfFile(gltf);
  }

  const fibroids = JSON.parse(localStorage.getItem("Fibroids"));

  let children = gltf.scene.children;

  while (children.length !== 419) {
    children = children[0].children;
  }

  children.forEach((child) => {
    if (child.name === "uterus") return;

    const fruit = fruitFilter[child.name];
    child.visible = false;

    Object.keys(fibroids).forEach((key) => {
      const fibroid = fibroids[key];
      const name = fibroid.key;

      if (
        child.name.includes(name) &&
        fruit.pedunculated === fibroid.pedunculated &&
        fruit.bodyPosition === fibroid.bodyPosition &&
        fruit.layer === fibroid.layer &&
        fruit.position === fibroid.position
      ) {
        console.log(child.name);
        child.visible = true;
      }
    });
  });

  return <primitive object={gltf.scene} />;
};

const SingleModelViewComponent = ({
  headerHeight,
  ModelURL,
  setGltfFile,
  trackingEnabled,
}) => {
  const OrbitControlsRef = useRef();

  const isSmallscreen = useSmallscreen();

  const [singleModeViewState, setSingleModeViewState] = useState({
    moveCamera: false,
    initialCameraZoomSmallScreen: 12,
    initialCameraZoomBigScreen: 12,
    axial: {
      initialCameraPosition: [0, -5, 0],
    },
    coronal: {
      initialCameraPosition: [-1, 0, 5],
    },
    sagittal: {
      initialCameraPosition: [5, 0, 0],
    },
    Trendelenburg: {
      initialCameraPosition: [-0.4, -3.95, 3.2],
    },
    repositionZoomMultiplier: 0.9,
    repositionCoordinates: [-1, -5, 5],
    sideGridXS: 4,
    centralGridXS: 4,
    singleViewHeight:
      window.innerHeight -
      parseInt(Main.getCommonScreenSettings().headerHeightBigScreen) +
      "px",
  });

  useEffect(() => {
    if (isSmallscreen) {
      setSingleModeViewState((prevState) => ({
        ...prevState,
        sideGridXS: 0,
        centralGridXS: 12,
        singleViewHeight: window.innerHeight - parseInt(headerHeight) + "px",
      }));
    } else {
      setSingleModeViewState((prevState) => ({
        ...prevState,
        sideGridXS: 3,
        centralGridXS: 6,
        singleViewHeight:
          window.innerHeight -
          parseInt(Main.getCommonScreenSettings().headerHeightBigScreen) +
          "px",
      }));
    }
  }, [isSmallscreen]);

  function MoveCamera({ repCoords }) {
    const { camera } = useThree();

    const newX = repCoords[0];
    const newY = repCoords[1];
    const newZ = repCoords[2];
    const currentCameraDistance = camera.position.distanceTo(
      OrbitControlsRef.current.target
    );
    useEffect(() => {
      camera.position.set(newX, newY, newZ);
      camera.position.setLength(currentCameraDistance);
      camera.updateProjectionMatrix();
    }, []);

    setSingleModeViewState((prevState) => ({
      ...prevState,
      moveCamera: false,
    }));

    return null;
  }

  function ControlVisibility() {
    const { scene } = useThree();

    useEffect(() => {
      Object.entries(Main.APP_PARAMS.globalState.meshes3Dmodel).map((item) => {
        scene.children.map((obj) => {
          obj.traverse((child) => {
            if (child.name === item[0]) {
              child.visible = item[1];
            }
          });
        });
      });
    }, []);

    return null;
  }

  function Labels() {
    const [state, setState] = useState(null);

    useEffect(() => {
      setState(Main.APP_PARAMS.globalState.meshesLabels);
    }, [Main.APP_PARAMS.globalState.meshesLabels]);

    if (state && state.visible) {
      return null;
    } else {
      return null;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12}>
          <Canvas
            style={{
              ...getStyles().mainPaper,
              ...{
                height: singleModeViewState.singleViewHeight,
              },
            }}
            camera={{
              zoom: isSmallscreen
                ? singleModeViewState.initialCameraZoomSmallScreen
                : singleModeViewState.initialCameraZoomBigScreen,
            }}
          >
            <spotLight intensity={0.5} penumbra={1} position={[-10, 10, 10]} />
            <spotLight
              intensity={0.5}
              penumbra={1}
              position={[-10, -10, -10]}
            />
            <spotLight intensity={0.5} penumbra={1} position={[10, -10, 10]} />
            <spotLight intensity={0.5} penumbra={1} position={[10, 10, -10]} />
            <Suspense fallback={<Loader />}>
              <Model
                model_url={ModelURL}
                setGltfFile={setGltfFile}
                trackingEnabled={trackingEnabled}
              />
            </Suspense>
            <ControlVisibility />
            <Labels />
            {singleModeViewState.moveCamera ? (
              <MoveCamera
                repCoords={singleModeViewState.repositionCoordinates}
              />
            ) : null}
            <OrbitControls ref={OrbitControlsRef} enablePan={false} />
          </Canvas>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const getStyles = () => {
  const styles = {
    mainPaper: { background: commonStyles.color.black, width: "100vw" },

    topLevelContainer: {
      background: commonStyles.color.black,
      height: "100%",
      paddingLeft: "3px",
      paddingRight: "3px",
    },
    button: {
      maxWidth: "200px",
    },
  };
  return styles;
};

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

function Loader() {
  const { progress } = useProgress();
  return (
    <Html center>
      <Typography style={{ color: "white" }}>
        {Math.round(progress)} % loaded
      </Typography>
    </Html>
  );
}

const COLORS = {
  0: { isColor: true, r: 230 / 255, g: 25 / 255, b: 75 / 255 },
  1: { isColor: true, r: 245 / 255, g: 130 / 255, b: 49 / 255 },
  2: { isColor: true, r: 255 / 255, g: 225 / 255, b: 25 / 255 },
  3: { isColor: true, r: 60 / 255, g: 180 / 255, b: 75 / 255 },
  4: { isColor: true, r: 66 / 255, g: 212 / 255, b: 244 / 255 },
  5: { isColor: true, r: 145 / 255, g: 30 / 255, b: 180 / 255 },
  6: { isColor: true, r: 70 / 255, g: 153 / 255, b: 144 / 255 },
  7: { isColor: true, r: 191 / 255, g: 239 / 255, b: 69 / 255 },
  8: { isColor: true, r: 0 / 255, g: 0 / 255, b: 117 / 255 },
  9: { isColor: true, r: 128 / 255, g: 128 / 255, b: 0 / 255 },
};

const ANATOMY_COLORS = {
  0: { isColor: true, r: 169 / 255, g: 169 / 255, b: 169 / 255 },
  1: { isColor: true, r: 255 / 255, g: 250 / 255, b: 200 / 255 },
  2: { isColor: true, r: 220 / 255, g: 190 / 255, b: 255 / 255 },
  3: { isColor: true, r: 128 / 255, g: 0 / 255, b: 0 / 255 },
  4: { isColor: true, r: 250 / 255, g: 190 / 255, b: 212 / 255 },
  5: { isColor: true, r: 67 / 255, g: 99 / 255, b: 216 / 255 },
  6: { isColor: true, r: 170 / 255, g: 255 / 255, b: 195 / 255 },
  7: { isColor: true, r: 154 / 255, g: 99 / 255, b: 36 / 255 },
  8: { isColor: true, r: 255 / 255, g: 216 / 255, b: 177 / 255 },
  9: { isColor: true, r: 240 / 255, g: 50 / 255, b: 230 / 255 },
};

const fruitYesAdd = (fruitName, index, key) => {
  let position = "Posterior";
  let bodyPosition = "Right Body";
  let layer = "Subserosal";

  const anteriorLocation = [index + 2, index + 3, index + 6, index + 7];
  const fundusLocation = [index + 8, index + 9];
  const lowerLocation = [index + 10, index + 11];

  const submucosalLayer = [
    index + 4,
    index + 5,
    index + 6,
    index + 7,
    index + 9,
    index + 11,
  ];

  if (anteriorLocation.includes(key)) position = "Anterior";
  if (fundusLocation.includes(key)) position = "Fundus";
  if (lowerLocation.includes(key)) position = "Lower Uterine Segment";
  if (
    key % 2 === 0 &&
    key < index + 8 &&
    position !== "Fundus" &&
    position !== "Lower Uterine Segment"
  )
    bodyPosition = "Left Body";
  if (submucosalLayer.includes(key)) layer = "Submucosal";

  fruitFilter[fruitName] = {
    position,
    bodyPosition,
    layer,
    pedunculated: "Pedunculated",
  };
};

const fruitNoAdd = (fruitName, index, key) => {
  let position = "Posterior";
  let bodyPosition = "Right Body";
  let layer = "Subserosal";

  const anteriorLocation = [
    index + 2,
    index + 3,
    index + 6,
    index + 7,
    index + 10,
    index + 11,
    index + 14,
    index + 15,
    index + 18,
    index + 19,
  ];
  const fundusLocation = [
    index + 20,
    index + 21,
    index + 22,
    index + 23,
    index + 24,
  ];
  const lowerLocation = [
    index + 25,
    index + 26,
    index + 27,
    index + 28,
    index + 29,
  ];

  const subserosalIntramuralLayer = [
    index + 4,
    index + 5,
    index + 6,
    index + 7,
    index + 21,
    index + 26,
  ];
  const intramuralLayer = [
    index + 8,
    index + 9,
    index + 10,
    index + 11,
    index + 22,
    index + 27,
  ];
  const intramuralSubmucosal = [
    index + 12,
    index + 13,
    index + 14,
    index + 15,
    index + 23,
    index + 28,
  ];
  const submucosalLayer = [
    index + 16,
    index + 17,
    index + 18,
    index + 19,
    index + 24,
    index + 29,
  ];

  if (anteriorLocation.includes(key)) position = "Anterior";
  if (fundusLocation.includes(key)) position = "Fundus";
  if (lowerLocation.includes(key)) position = "Lower Uterine Segment";

  if (
    key % 2 === 0 &&
    key < index + 20 &&
    position !== "Fundus" &&
    position !== "Lower Uterine Segment"
  )
    bodyPosition = "Left Body";

  if (subserosalIntramuralLayer.includes(key)) layer = "Subserosal/Intramural";
  if (intramuralLayer.includes(key)) layer = "Intramural";
  if (intramuralSubmucosal.includes(key)) layer = "Intramural/Submucosal";
  if (submucosalLayer.includes(key)) layer = "Submucosal";

  fruitFilter[fruitName] = {
    position,
    bodyPosition,
    layer,
    pedunculated: "Not Pedunculated",
  };
};

[...Array(12).keys()]
  .map((key) => {
    return `currant_pdnc_grp${key + 1}`;
  })
  .forEach((fruitName, i) => {
    fruitYesAdd(fruitName, 1, i + 1);
  });

[...Array(30).keys()]
  .map((key) => {
    return `currant_grp${key + 13}`;
  })
  .forEach((fruitName, i) => {
    fruitNoAdd(fruitName, 13, i + 13);
  });

[...Array(12).keys()]
  .map((key) => {
    return `blueberry_pdnc_grp${key + 43}`;
  })
  .forEach((fruitName, i) => {
    fruitYesAdd(fruitName, 43, i + 43);
  });

[...Array(30).keys()]
  .map((key) => {
    return `blueberry_grp${key + 55}`;
  })
  .forEach((fruitName, i) => {
    fruitNoAdd(fruitName, 55, i + 55);
  });

[...Array(12).keys()]
  .map((key) => {
    return `rasp_pdnc_grp${key + 85}`;
  })
  .forEach((fruitName, i) => {
    fruitYesAdd(fruitName, 85, i + 85);
  });

[...Array(30).keys()]
  .map((key) => {
    return `rasp_grp${key + 97}`;
  })
  .forEach((fruitName, i) => {
    fruitNoAdd(fruitName, 97, i + 97);
  });

[...Array(12).keys()]
  .map((key) => {
    return `grape_pdnc_grp${key + 127}`;
  })
  .forEach((fruitName, i) => {
    fruitYesAdd(fruitName, 127, i + 127);
  });

[...Array(30).keys()]
  .map((key) => {
    return `grape_grp${key + 139}`;
  })
  .forEach((fruitName, i) => {
    fruitNoAdd(fruitName, 139, i + 139);
  });

[...Array(12).keys()]
  .map((key) => {
    return `cherry_pdnc_grp${key + 169}`;
  })
  .forEach((fruitName, i) => {
    fruitYesAdd(fruitName, 169, i + 169);
  });

[...Array(30).keys()]
  .map((key) => {
    return `cherry_grp${key + 181}`;
  })
  .forEach((fruitName, i) => {
    fruitNoAdd(fruitName, 181, i + 181);
  });

[...Array(12).keys()]
  .map((key) => {
    return `strawb_pdnc_grp${key + 211}`;
  })
  .forEach((fruitName, i) => {
    fruitYesAdd(fruitName, 211, i + 211);
  });

[...Array(30).keys()]
  .map((key) => {
    return `strawb_grp${key + 223}`;
  })
  .forEach((fruitName, i) => {
    fruitNoAdd(fruitName, 223, i + 223);
  });

[...Array(12).keys()]
  .map((key) => {
    return `lime_pdnc_grp${key + 253}`;
  })
  .forEach((fruitName, i) => {
    fruitYesAdd(fruitName, 253, i + 253);
  });

[...Array(30).keys()]
  .map((key) => {
    return `lime_grp${key + 265}`;
  })
  .forEach((fruitName, i) => {
    fruitNoAdd(fruitName, 265, i + 265);
  });

[...Array(12).keys()]
  .map((key) => {
    return `peach_pdnc_grp${key + 295}`;
  })
  .forEach((fruitName, i) => {
    fruitYesAdd(fruitName, 295, i + 295);
  });

[...Array(30).keys()]
  .map((key) => {
    return `peach_grp${key + 307}`;
  })
  .forEach((fruitName, i) => {
    fruitNoAdd(fruitName, 307, i + 307);
  });

[...Array(12).keys()]
  .map((key) => {
    return `mango_pdnc_grp${key + 337}`;
  })
  .forEach((fruitName, i) => {
    fruitYesAdd(fruitName, 337, i + 337);
  });

[...Array(30).keys()]
  .map((key) => {
    return `mango_grp${key + 349}`;
  })
  .forEach((fruitName, i) => {
    fruitNoAdd(fruitName, 349, i + 349);
  });

[...Array(12).keys()]
  .map((key) => {
    return `watermelon_pdnc_grp${key + 379}`;
  })
  .forEach((fruitName, i) => {
    fruitYesAdd(fruitName, 379, i + 379);
  });

[...Array(30).keys()]
  .map((key) => {
    return `watermelon_grp${key + 391}`;
  })
  .forEach((fruitName, i) => {
    fruitNoAdd(fruitName, 391, i + 391);
  });

export default SingleModelViewComponent;
