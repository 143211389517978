import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Typography,
  Button,
  Select,
  MenuItem,
  Divider,
  Grid,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import useSmallscreen from "../../hooks/smallscreenHook";
import { ROUTES_LIST } from "../../commonUtils/constants";

import commonStyles from "../../commonUtils/commonStyles";
import { fetchData } from "../../utils/AwsFunctions";

export default function Introduction() {
  const isSmallScreen = useSmallscreen();
  const [pageStep, setPageStep] = useState(0);

  const positions = [
    "Anterior",
    "Posterior",
    "Fundus",
    "Lower Uterine Segment",
  ];
  const bodyPositions = ["Right Body", "Left Body"];
  const layers = [
    "Subserosal",
    "Subserosal/Intramural",
    "Intramural",
    "Intramural/Submucosal",
    "Submucosal",
  ];
  const pedunculateds = ["Pedunculated", "Not Pedunculated"];
  const fibroids = {};
  [...Array(10).keys()].map((key) => {
    fibroids[key] = {
      name: "Currant",
      key: "currant_",
      size: 0.5,
      position: positions[0],
      bodyPosition: bodyPositions[0],
      layer: layers[0],
      pedunculated: pedunculateds[1],
    };
  });
  const [fibroidsSelect, setFibroidsSelect] = useState(fibroids);
  const [fibroidAmount, setFibroidAmount] = useState(1);

  return (
    <ThemeProvider theme={theme}>
      <Paper
        elevation={0}
        style={{
          marginTop: isSmallScreen && pageStep === 1 ? "" : "100px",
          background: commonStyles.color.greyLight,
        }}
      >
        <Grid
          container
          style={{
            marginTop: isSmallScreen && pageStep === 1 ? "" : "70px",
            minHeight: "100px",
            height: isSmallScreen && pageStep === 1 ? "100%" : "",
            maxWidth: "100%",
          }}
        >
          <Grid
            container
            style={{
              padding: isSmallScreen ? "15px" : "30px",
              marginBottom: isSmallScreen && pageStep === 1 ? "170px" : "",
            }}
          >
            <Grid item xs={12} align="center">
              <Typography
                noWrap
                style={{
                  ...commonStyles.text.robotoBold30,
                  marginBottom: "30px",
                }}
              >
                myFruiterus Demo
              </Typography>
              {pageStep === 0 ? (
                <IntroductionComponent
                  onClick={() => {
                    setPageStep(1);
                  }}
                />
              ) : (
                ""
              )}
              {pageStep === 1 ? (
                <SelectModelComponent
                  positions={positions}
                  bodyPositions={bodyPositions}
                  layers={layers}
                  pedunculateds={pedunculateds}
                  fibroidsSelect={fibroidsSelect}
                  setFibroidsSelect={setFibroidsSelect}
                  fibroidAmount={fibroidAmount}
                  setFibroidAmount={setFibroidAmount}
                  onClick={() => {
                    setPageStep(2);
                  }}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}

const IntroductionComponent = ({ onClick }) => {
  return (
    <div>
      <Grid item xs={0} md={2} />
      <Grid item xs={12} md={8} align="left">
        <Typography>
          Welcome to myFruiterus. This light-hearted tool was developed to help
          you better understand how fibroids are affecting your anatomy, since
          we know so many women are not able to fully visualize what is written
          in a radiology report or shown in medical images.
        </Typography>
      </Grid>
      <Grid item xs={0} md={2} />
      <Grid item xs={0} md={2} />
      <Grid
        item
        xs={12}
        md={8}
        align="left"
        style={{ ...{ marginTop: "30px" } }}
      >
        <Typography>
          This tool was developed by physicians and engineers to help educate
          you on what questions to ask about treatment options for uterine
          fibroids. However, this tool is not a diagnostic and will not
          substitute getting formal advice from a board-certified physician.
        </Typography>
      </Grid>
      <Grid item xs={0} md={2} />
      <Grid item xs={12} align="center" style={{ ...{ marginTop: "50px" } }}>
        <Button variant="contained" onClick={() => onClick()}>
          Proceed
        </Button>
      </Grid>
    </div>
  );
};

const SelectModelComponent = ({
  positions,
  bodyPositions,
  layers,
  pedunculateds,
  fibroidsSelect,
  setFibroidsSelect,
  fibroidAmount,
  setFibroidAmount,
}) => {
  const isSmallScreen = useSmallscreen();
  const navigate = useNavigate();

  const fruitTypes = {
    0.5: ["Currant", "currant_"],
    1: ["Blueberry", "blueberry_"],
    1.5: ["Raspberry", "rasp_"],
    2: ["Grape", "grape_"],
    3: ["Cherry", "cherry_"],
    4.5: ["Strawberry", "strawb_"],
    6: ["Lime", "lime_"],
    8: ["Peach", "peach_"],
    12: ["Mango", "mango_"],
    20: ["Watermelon", "watermelon_"],
  };

  const orderTxt = [
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
  ];
  const fibroidsSize = [0.5, 1, 1.5, 2, 3, 4.5, 6, 8, 12, 20];

  const [fibroidOrder, setFibroidOrder] = useState(1);

  const [isBodyPosition, setIsBodyPosition] = useState(true);
  const [isPedunculated, setIsPedunculated] = useState(true);

  useEffect(() => {
    const fibroidSelect = fibroidsSelect[fibroidOrder - 1];
    if (
      fibroidSelect.position === "Fundus" ||
      fibroidSelect.position === "Lower Uterine Segment"
    ) {
      setIsBodyPosition(false);
      return;
    }

    setIsBodyPosition(true);
  }, [fibroidsSelect, fibroidOrder]);

  useEffect(() => {
    const fibroidSelect = fibroidsSelect[fibroidOrder - 1];
    if (
      fibroidSelect.layer === "Subserosal" ||
      fibroidSelect.layer === "Submucosal"
    ) {
      setIsPedunculated(true);
      return;
    }

    setIsPedunculated(false);
  }, [fibroidsSelect, fibroidOrder]);

  const onChangeFiroidAmount = (value) => {
    setFibroidAmount(value);
  };

  const onClickArrowButton = (order) => {
    if (fibroidOrder === order) return;

    setFibroidOrder(order);
  };

  const onChangeSize = (value) => {
    fibroidsSelect[fibroidOrder - 1].size = value;
    fibroidsSelect[fibroidOrder - 1].name = fruitTypes[value][0];
    fibroidsSelect[fibroidOrder - 1].key = fruitTypes[value][1];

    setFibroidsSelect((prevState) => ({
      ...prevState,
    }));
  };

  const onChangePosition = (value) => {
    fibroidsSelect[fibroidOrder - 1].position = value;

    if (value === "Fundus" || value === "Lower Uterine Segment") {
      fibroidsSelect[fibroidOrder - 1].bodyPosition = "Right Body";
    }

    setFibroidsSelect((prevState) => ({
      ...prevState,
    }));
  };

  const onChangeBodyPosition = (value) => {
    fibroidsSelect[fibroidOrder - 1].bodyPosition = value;

    setFibroidsSelect((prevState) => ({
      ...prevState,
    }));
  };

  const onChangeLayer = (value) => {
    const oldLayer = fibroidsSelect[fibroidOrder - 1].layer;
    if (
      (value === "Subserosal" || value === "Submucosal") &&
      oldLayer !== "Subserosal" &&
      oldLayer !== "Submucosal"
    ) {
      fibroidsSelect[fibroidOrder - 1].pedunculated = "Not Pedunculated";
    }
    if (
      (oldLayer === "Subserosal" || oldLayer === "Submucosal") &&
      value !== "Subserosal" &&
      value !== "Submucosal"
    ) {
      fibroidsSelect[fibroidOrder - 1].pedunculated = "Not Pedunculated";
    }

    fibroidsSelect[fibroidOrder - 1].layer = value;

    setFibroidsSelect((prevState) => ({
      ...prevState,
    }));
  };

  const onChangePedunculated = (value) => {
    fibroidsSelect[fibroidOrder - 1].pedunculated = value;

    setFibroidsSelect((prevState) => ({
      ...prevState,
    }));
  };

  const fetchPatientMaster = async () => {
    const res = await fetchData("patients");
    return res.filter((patient) => {
      return patient.patient_id === "myfruiterus_master";
    })[0];
  };

  const movetoRender3D = async () => {
    const patient = await fetchPatientMaster();
    const patientData = {
      patientId: patient.patient_id,
      patientName: patient.name,
      patientBirthday: patient.birthday,
    };
    const bodyMeshes = patient.body_meshes || "";
    const fibroidMeshes = patient.Fibroid_meshes || "";

    const bodyMeshesArr = bodyMeshes.split("&");
    const fibroidsMeshesArr = fibroidMeshes.split("&");
    const bodyMeshesObj = bodyMeshesArr.reduce(
      (a, v) => ({ ...a, [v]: true }),
      {}
    );
    const fibroidsMeshesObj = fibroidsMeshesArr.reduce(
      (a, v) => ({ ...a, [v]: true }),
      {}
    );
    const mergedMeshesObj = { ...bodyMeshesObj, ...fibroidsMeshesObj };
    const modelData = {
      modelURL: patient.url,
      bodyMeshesObj: bodyMeshesObj,
      fibroidsMeshesObj: fibroidsMeshesObj,
      mergedMeshesObj: mergedMeshesObj,
    };

    localStorage.setItem("ModelData", JSON.stringify(modelData));
    localStorage.setItem("PatientData", JSON.stringify(patientData));
    localStorage.setItem("Colors", JSON.stringify(patient.colors || null));

    const fibroids = JSON.parse(JSON.stringify(fibroidsSelect));
    for (let i = 10; i >= fibroidAmount; i--) {
      delete fibroids[i];
    }
    localStorage.setItem("Fibroids", JSON.stringify(fibroids));

    navigate(ROUTES_LIST.render3Dmodel);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          align="left"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <Typography align="justify">
            Please help us fruiterize your fruiterus by answering the following
            questions:
          </Typography>
          <div
            style={{
              position: "relative",
              paddingTop: "15px",
              display: "flex",
            }}
          >
            <Typography
              style={{
                paddingTop: "5px",
              }}
            >
              How many fibroids are listed?
            </Typography>
            <Select
              value={fibroidAmount}
              onChange={(e) => onChangeFiroidAmount(e.target.value)}
              style={{
                height: "35px",
                width: "70px",
                marginLeft: "20px",
                backgroundColor: "#ffffff",
              }}
            >
              {[...Array(10).keys()].map((key) => {
                const val = key + 1;
                return (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </Grid>
        <Divider
          style={{
            margin: "30px 0",
            border: "2px solid #ffffff",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, 110px)",
          justifyContent: "space-between",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "50px",
        }}
      >
        {[...Array(fibroidAmount).keys()].map((i) => {
          return (
            <ArrowButton
              key={i}
              isSmallScreen={isSmallScreen}
              text={`Fibroid ${Number(i) + 1}`}
              isSelected={fibroidOrder === Number(i) + 1}
              onClick={() => onClickArrowButton(Number(i) + 1)}
            />
          );
        })}
      </Grid>
      {Object.keys(fibroidsSelect).map((i) => {
        return (
          <Grid
            item
            xs={12}
            key={i}
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              position: "relative",
              display: Number(i) + 1 === fibroidOrder ? "" : "none",
            }}
          >
            <Grid container>
              <Grid item xs={isSmallScreen ? 12 : 4} align="justify" order={1}>
                {`a. Please enter the largest dimension of the ${orderTxt[i]} fibroid in
                centimeters (cm).`}
              </Grid>
              <Grid
                item
                xs={isSmallScreen ? 12 : 8}
                align="justify"
                order={isSmallScreen ? 3 : 2}
                style={{
                  paddingLeft: isSmallScreen ? "" : "60px",
                  marginTop: isSmallScreen ? "20px" : "",
                }}
              >
                {`b. Please select all the position(s) listed for the ${orderTxt[i]}
                fibroid.`}
              </Grid>
              <Grid item xs={isSmallScreen ? 12 : 4} align="left" order={2}>
                <Select
                  value={fibroidsSelect[i].size}
                  onChange={(e) => onChangeSize(e.target.value)}
                  style={{
                    height: "35px",
                    width: "100px",
                    marginTop: isSmallScreen ? "20px" : "10px",
                    backgroundColor: "#ffffff",
                    float: "left",
                  }}
                >
                  {fibroidsSize.map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {key + " cm"}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                align="left"
                order={isSmallScreen ? 4 : 4}
              >
                <Select
                  value={fibroidsSelect[i].position}
                  onChange={(e) => onChangePosition(e.target.value)}
                  style={{
                    height: "35px",
                    width: isSmallScreen ? "100%" : "220px",
                    marginTop: isSmallScreen ? "20px" : "10px",
                    marginLeft: isSmallScreen ? "" : "60px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  {positions.map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={5}
                align="left"
                order={isSmallScreen ? 5 : 5}
              >
                <Select
                  value={fibroidsSelect[i].bodyPosition}
                  onChange={(e) => onChangeBodyPosition(e.target.value)}
                  style={{
                    height: "35px",
                    width: isSmallScreen ? "100%" : "180px",
                    marginTop: "10px",
                    backgroundColor: "#ffffff",
                    display: isBodyPosition ? "" : "none",
                  }}
                >
                  {bodyPositions.map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={4} order={isSmallScreen ? 6 : 6} />
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                align="left"
                order={isSmallScreen ? 7 : 7}
              >
                <Select
                  value={fibroidsSelect[i].layer}
                  onChange={(e) => onChangeLayer(e.target.value)}
                  style={{
                    height: "35px",
                    width: isSmallScreen ? "100%" : "220px",
                    marginTop: isSmallScreen ? "10px" : "20px",
                    marginLeft: isSmallScreen ? "" : "60px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  {layers.map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={5}
                align="left"
                order={isSmallScreen ? 8 : 8}
              >
                <Select
                  value={fibroidsSelect[i].pedunculated}
                  onChange={(e) => onChangePedunculated(e.target.value)}
                  style={{
                    height: "35px",
                    width: isSmallScreen ? "100%" : "180px",
                    marginTop: isSmallScreen ? "10px" : "20px",
                    backgroundColor: "#ffffff",
                    display: isPedunculated ? "" : "none",
                  }}
                >
                  {pedunculateds.map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={0} md={8} lg={7} order={isSmallScreen ? 9 : 9} />
              <Grid
                item
                xs={isSmallScreen ? 12 : 4}
                align="left"
                order={isSmallScreen ? 10 : 10}
                style={{
                  marginTop: "60px",
                }}
              >
                <Button
                  endIcon={
                    <ArrowRightIcon
                      style={{
                        fontSize: "30px",
                        marginRight: "-16px",
                        marginLeft: "-8px",
                      }}
                    />
                  }
                  onClick={movetoRender3D}
                  style={{
                    backgroundColor: "#1c7a8b",
                    color: "#ffffff",
                    marginLeft: isSmallScreen ? "calc(100% - 100px)" : "80px",
                    width: "100px",
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

const ArrowButton = ({ isSmallScreen, text, isSelected, onClick }) => {
  return (
    <svg
      width="110"
      height="42"
      style={{
        cursor: "pointer",
        marginBottom: "10px",
      }}
      onClick={onClick}
      onTouchEnd={onClick}
    >
      <path
        stroke="#000"
        d="m0,0.05833c30.7907,0.20205 61.94483,-0.20206 92.73553,0l17.04435,19.85201l-16.44614,23.18589l-93.2926,0l13.97425,-22.57872l-14.01539,-20.45918z"
        strokeWidth="1"
        style={{ fill: isSelected ? "#0d5663" : "#1c7a8b" }}
      />
      <text
        stroke="#000"
        fontSize="15"
        y="25.5"
        x="27"
        strokeWidth="0"
        fill="#ffffff"
      >
        {text}
      </text>
    </svg>
  );
};

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});
