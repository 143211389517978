import React, { useState, useEffect, useCallback } from "react";

import { Grid, Typography } from "@mui/material";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import useSmallscreen from "../../../hooks/smallscreenHook";

import commonStyles from "../../../commonUtils/commonStyles";

import Main from "../../../main/Main";

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function HeaderComponent({ initialViewMode, setHeaderHeight }) {
  const isSmallscreen = useSmallscreen();

  const [state, setState] = useState({
    userName: "LongSurname, LongName",
    userDate: "06-28-1973",
    userId: "ID#1234567",
    multiViewerEnabled: true,
  });

  const headerRef = useCallback((element) => {
    if (!element) return;

    setHeaderHeight(element.clientHeight);
  }, []);

  const fibroids = JSON.parse(localStorage.getItem("Fibroids"));

  useEffect(() => {
    const patientData = JSON.parse(localStorage.getItem("PatientData"));

    setState({
      ...state,
      userName: patientData.patientName,
      userDate: patientData.patientBirthday,
      userId: "ID# " + patientData.patientId,
      multiViewerEnabled: initialViewMode === 2 ? true : false,
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        ref={headerRef}
        container
        direction={"row"}
        style={{
          ...getStyles().mainPaper,
          ...{
            minHeight: isSmallscreen
              ? Main.getCommonScreenSettings().headerHeightSmallScreen
              : Main.getCommonScreenSettings().headerHeightBigScreen,
          },
        }}
      >
        <Grid item xs={12}>
          <Grid
            container
            direction={"row"}
            alignItems="center"
            style={getStyles().topLevelContainer}
          >
            <Grid
              item
              xs={12}
              align="center"
              style={{ ...getStyles().containerOutline }}
            >
              <Typography
                style={{ ...getStyles().middleText, paddingTop: "10px" }}
              >
                Based on your answers, your uterus has the following fruitoids:
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "10px",
                  flexWrap: "wrap",
                }}
              >
                {Object.keys(fibroids).map((key) => {
                  return (
                    <div
                      key={key}
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography style={getStyles().middleText}>
                        {`Fibroid ${Number(key) + 1}:`}
                      </Typography>
                      <Typography style={getStyles().middleText}>
                        {fibroids[key].name}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: {
      background: commonStyles.color.backgroundDark,
      width: "100%",
    },
    topLevelContainer: {
      height: "100%",
    },
    containerOutline: {},
    middleText: {
      color: "white",
      fontSize: "14px",
      textOverflow: "elipsis",
    },
    button: {
      maxWidth: "100px",
    },
  };
  return styles;
};
