import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, TextField, Typography, Button, FormControl } from '@mui/material';
import ToastComponent from '../Components/ToastComponent/ToastComponent';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useServerRequest from '../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../commonUtils/constants';
import { ROUTES_LIST } from '../../commonUtils/constants';
import  useSmallscreen from '../../hooks/smallscreenHook';

import commonStyles from '../../commonUtils/commonStyles';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blueDisabled,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function TestDBScreen() {
  const navigate = useNavigate();
  const isSmallScreen = useSmallscreen();

  const setGlobalLoading = (val) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalLoading: val,
    }));
  };

  const [state, setState] = useState({
    testData: '',
    userName: '',
    password: '',
    modelURL: '',
  });

  const [ error, showError] = useState(false);
  const { getRequest: loadInitialData } = useServerRequest(setGlobalLoading);
  //const { getRequest: uploadData } = useServerRequest(setGlobalLoading);


  useEffect(() => {
    //call function when something change in state
  }, [state]); //dependency added

  const updateText = (name, val) => {
    setState((prevState) => ({
      ...prevState,
      [name]: name === 'userName' ? val.replace(/ /g,'') : val,
    }));
  };

  const handleSubmitTestDB = () => {
    loadInitialData(API_ENDPOINTS_LIST.getTestData, '', 'GET').then((res) => {
      //console.log('loadData result: ' + JSON.stringify(res));
      //console.log('loadTableData result: ' + JSON.stringify(res.clientsStatuses));

      setState((prevState) => ({
        ...prevState,
        testData: JSON.stringify(res),
      }));
    });
  };

  const handleSubmitLoad = () => {
    if (state.userName === 'WCMpilot' && state.password === 'myocad908!') {
      navigate(ROUTES_LIST.selectModel);
    } else {
      showError(true);
      setTimeout(() => {
        showError(false);
      }, 3000);
    }
  };

  const handleSubmitTest = () => {
    //console.log(state.modelURL);
    //Main.test3DmodelURL = state.modelURL;
    if (state.modelURL) {
      localStorage.setItem('testModelURL', JSON.stringify(state.modelURL));
      navigate(ROUTES_LIST.load3Dmodel);
    }
  };

  const render3DModelButton = () => (
    <Button
      color="secondary" 
      style={{ ...{ minWidth: '180px' } }}
      variant="contained"
      disabled={state.modelURL === '' ? true : false}
      onClick={() => handleSubmitTest()}
    >
      Test 3D model
   </Button>
  );

  const renderDBConnectionButton = () => (
    <Button color="secondary" style={{ ...{ minWidth: '180px', marginLeft: !isSmallScreen && '20px' } }} variant="contained" onClick={() => handleSubmitTestDB()}>
      Test DB connection
    </Button>
  );

  return (
    <ThemeProvider theme={theme}>
      <Paper style={isSmallScreen ? getMobileStyles().mainPaper : getMobileStyles(isSmallScreen).mainPaper }>
        <Grid container direction="row" style={getMobileStyles(isSmallScreen).topTextGrid}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={getMobileStyles(isSmallScreen).topTextGridColumn}>
            <Grid item xs={12} align="center">
              <Typography noWrap style={commonStyles.text.robotoBold30}>
                myFruiterus Demo
              </Typography>
              <Grid item xs={12} align="center" style={{ ...{ marginTop: '60px' } }}>
                <FormControl fullWidth style={{ ...{ maxWidth: !isSmallScreen && '350px' } }}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    name="Username"
                    label="Username"
                    variant="outlined"
                    value={state.userName}
                    onChange={(e) => updateText('userName', e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} align="center" style={{ ...{ marginTop: '30px' } }}>
                <FormControl fullWidth  style={{ ...{ maxWidth: !isSmallScreen && '350px'  } }}>
                  <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    variant="outlined"
                    value={state.password}
                    onChange={(e) => updateText('password', e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} align="center" style={{ ...{ marginTop: '30px' } }}>
              <FormControl fullWidth style={{ ...{ maxWidth: !isSmallScreen && '350px' } }}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Model URL for Testing"
                  variant="outlined"
                  value={state.modelURL}
                  onChange={(e) => updateText('modelURL', e.target.value)}
              />
              </FormControl>
              </Grid>
             </Grid>
            <Grid item xs={12} align="center" style={{ ...{ marginTop: '120px' } }}>
              <Button  style={{ ...{ minWidth: '180px' } }} variant="contained" onClick={handleSubmitLoad}>
                Login
              </Button>
            </Grid>
            {!isSmallScreen ? (
             <Grid item xs={12} align="center" style={{ ...{ marginTop: '30px' } }}>
               {render3DModelButton()}
               {renderDBConnectionButton()}
              </Grid>
              ) : (
                <>
                  <Grid item xs={12} align="center" style={{ ...{ marginTop: '30px' } }}>
                    {render3DModelButton()}
                  </Grid>
                  <Grid item xs={12} align="center" style={{ ...{ marginTop: '30px' } }}>
                    {renderDBConnectionButton()}          
                </Grid>
                </>
               )
             }
              {state.testData !== '' ? (
                <Typography
                  style={{
                    ...commonStyles.text.robotoRegular18,
                    ...{ marginTop: '70px', color: 'green' },
                  }}
                >
                  Answer from server: <br />
                  {state.testData}
                </Typography>
              ) : null}
  
          </Grid>
        </Grid>
        {
          error && <ToastComponent open={true} message="Invalid Username/Password" styles="error" /> 
        }
      </Paper>
    </ThemeProvider>
  );
}

// const getStyles = (isSmallScreen) => {
//   const styles = {
//     mainPaper: { marginTop: isSmallScreen ? '0px' : '100px' , background: commonStyles.color.greyLight, marginBottom: '0px', },
//     topTextGrid: {
//       marginTop: isSmallScreen ? '0px' : '70px',
//       minHeight: '100px',
//       maxWidth: '100%',
//       //background: '#FFAA00',
//     },
//     topTextGridColumn: {
//       //background: '#99FF99',
//       padding: '30px',
//     },
//   };
//   return styles;
// };

const getMobileStyles = () => {
  const styles = {
    mainPaper: { background: commonStyles.color.greyLight, height: '100vh', width: '100%' },
    topTextGrid: {
      background: commonStyles.color.greyLight,
      marginTop: '0px', 
      minHeight: '100%',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn: {
      //background: '#99FF99',
      padding: '30px',
    },
  };
  return styles;
};
