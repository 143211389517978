import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme } from "@mui/material/styles";

import commonStyles from "../commonUtils/commonStyles";

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

//screens below MD breakpoint is small screens
const useSmallscreen = () => {
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (matches) {
      setSmall(false);
    } else {
      setSmall(true);
    }
  }, [matches]);
  return small;
};

export default useSmallscreen;
