import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import useServerRequest from '../../hooks/serverRequest';
import useSmallscreen from '../../hooks/smallscreenHook';
import { API_ENDPOINTS_LIST } from '../../commonUtils/constants';
import { ROUTES_LIST } from '../../commonUtils/constants';

import commonStyles from '../../commonUtils/commonStyles';
import {fetchData} from '../../utils/AwsFunctions';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function SelectModelScreen() {
  const navigate = useNavigate();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const [state, setState] = useState({
    modelURL: '',
    modelMeshesBody: '',
    modelMeshesFibroids: '',
    modelId: '',
    modelUsername: '',
    modelUserBirthday: '',
  });

  const [selectedPatient, setSelectedPatient] = useState('');
  const [patientList, setPatientList] = useState([]);
  const isSmallScreen = useSmallscreen();

  //const { getRequest: uploadData } = useServerRequest(setGlobalLoading);

  const fetchDataFormDynamoDb = async () => {
      const res = await fetchData('patients')
      res.sort((a, b) => {
        if (a?.name && b?.name) {
          return a.name.localeCompare(b.name);
        }
      })
      setPatientList(res);
  }

  useEffect(() => {
    fetchDataFormDynamoDb();
  }, []);

  useEffect(() => {
    //call function when something change in state
    if (
      state.modelURL !== '' &&
      state.modelMeshesBody !== '' &&
      state.modelMeshesFibroids !== '' &&
      state.modelId !== '' &&
      state.modelUsername !== '' &&
      state.modelUserBirthday !== ''
    ) {
      //setSubmitButtonDisabled(false);
    } else {
      //setSubmitButtonDisabled(true);
    }
  }, [state]); //dependency added


  useEffect(() => {
    const pat = patientList.find((pt) => pt.patient_id === selectedPatient)
    const newData = {
        modelMeshesFibroids: pat?.Fibroid_meshes || '',
        modelUserBirthday: pat?.birthday,
        modelMeshesBody: pat?.body_meshes,
        modelUsername: pat?.name,
        modelId: pat?.patient_id,
        modelURL: pat?.url,
        colors: pat?.colors || null
    };
    setState({
      ...newData
    })
  }, [selectedPatient]); //dependency added

  const updateText = (name, val) => {
    setState((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };

  const handleSubmitLoadingButton = () => {
    const patientData = {
      patientId: state.modelId,
      patientName: state.modelUsername,
      patientBirthday: state.modelUserBirthday,
    };
    const bodyMeshesArr = state.modelMeshesBody.split('&');
    const fibroidsMeshesArr = state.modelMeshesFibroids.split('&');
    const bodyMeshesObj = bodyMeshesArr.reduce((a, v) => ({ ...a, [v]: true }), {});
    const fibroidsMeshesObj = fibroidsMeshesArr.reduce((a, v) => ({ ...a, [v]: true }), {});
    const mergedMeshesObj = { ...bodyMeshesObj, ...fibroidsMeshesObj };
    const modelData = {
      modelURL: state.modelURL,
      bodyMeshesObj: bodyMeshesObj,
      fibroidsMeshesObj: fibroidsMeshesObj,
      mergedMeshesObj: mergedMeshesObj,
    };

    //Main.test3DmodelURL = state.modelURL;
    localStorage.setItem('ModelData', JSON.stringify(modelData));
    localStorage.setItem('PatientData', JSON.stringify(patientData));
    localStorage.setItem('Colors', JSON.stringify(state.colors));

    navigate(ROUTES_LIST.render3Dmodel);
  };

  const handleChange = (e) => {
    setSelectedPatient(e.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper style={getStyles().mainPaper} elevation={0}>
        <Grid container direction="row" style={getStyles().topTextGrid}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={getStyles().topTextGridColumn}>
            <Grid item xs={12} align="center">
              <Typography noWrap style={commonStyles.text.robotoBold30}>
                myFruiterus Demo
              </Typography>
            </Grid>
            <Grid item xs={12} align="center" style={{ ...{ marginTop: '60px' } }}>
            <FormControl fullWidth style={{ ...{ maxWidth: !isSmallScreen && '350px' } }} >
              <InputLabel id="demo-simple-select-label" > Patients </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPatient}
                label="Patient"
                onChange={handleChange}
              >
                {
                  patientList.map((patient) => {
                    return <MenuItem key={patient.patient_id} value={patient.patient_id}>{patient.name}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} align="center" style={{ ...{ marginTop: '50px' } }}>
              <Button
                //disabled={submitButtonDisabled}
                variant="contained"
                onClick={() => handleSubmitLoadingButton()}
              >
                Load 3D model
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: { marginTop: '100px', background: commonStyles.color.greyLight },
    topTextGrid: {
      marginTop: '70px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn: {
      //background: '#99FF99',
      padding: '30px',
    },
  };
  return styles;
};
