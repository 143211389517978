export const ROUTES_LIST = {
  home: '/home',
  testDB: '/test-db',
  selectModel: '/select-model',
  render3Dmodel: '/render-model',
  load3Dmodel: '/load-model',
};

export const API_ENDPOINTS_LIST = {
  //tests
  getTestData: '/test_db/users',
};
