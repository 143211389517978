import React, { useState, useEffect, useRef } from 'react';

import { Grid, Slide, Typography, Button, Switch } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import useSmallscreen from '../../../hooks/smallscreenHook';
import useWindowDimensions from '../../../hooks/windowDimensionsHook';

import FibroidsMenuListComponent from './FibroidsMenuListComponent';

import commonStyles from '../../../commonUtils/commonStyles';

import Main from '../../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function FibroidsMenuComponent({ showMenu, gltfFile, trackingEnabled, setGltfFile, setMenuState, menuState }) {
  const isSmallscreen = useSmallscreen();
  const windowDimensions = useWindowDimensions();

  const [viewState, setViewState] = useState({
    height: window.innerHeight - parseInt(Main.getCommonScreenSettings().headerHeightBigScreen) + 'px',
    width: parseInt(Math.floor(window.innerWidth / 2)) + 'px',
  });

  useEffect(() => {
    if (isSmallscreen) {
      setViewState((prevState) => ({
        ...prevState,

        height: window.innerHeight - parseInt(Main.getCommonScreenSettings().headerHeightSmallScreen) + 'px',
      }));
    } else {
      setViewState((prevState) => ({
        ...prevState,
        height: window.innerHeight - parseInt(Main.getCommonScreenSettings().headerHeightBigScreen) + 'px',
      }));
    }
  }, [isSmallscreen]);

  useEffect(() => {
    //console.log(JSON.stringify(windowDimensions));
    setViewState((prevState) => ({
      ...prevState,
      width: isSmallscreen
        ? parseInt(Math.floor(320)) + 'px'
        : parseInt(Math.floor(380)) + 'px',
    }));
  }, [windowDimensions, isSmallscreen]);

  return (
    <ThemeProvider theme={theme}>
      <Slide direction="down" in={showMenu} mountOnEnter unmountOnExit>
        <Grid
          container
          style={{
            ...getStyles().mainPaper,
            ...{
              top: isSmallscreen
                ? Main.getCommonScreenSettings().headerHeightSmallScreen
                : Main.getCommonScreenSettings().headerHeightBigScreen,
              right: 0,
              height: viewState.height,
              width: viewState.width,
            },
          }}
        >
          <Grid item xs={12} style={{ maxHeight: viewState.height, overflow: 'auto' }}>
            <FibroidsMenuListComponent
              listWidth={viewState.width}
              gltfFile={gltfFile}
              setGltfFile={setGltfFile}
              trackingEnabled={trackingEnabled}
              setMenuState={setMenuState}
              menuState={menuState}
            />
          </Grid>
        </Grid>
      </Slide>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: {
      position: 'absolute',
      background: 'rgba(200,200,444, 0.5)',
      //width: '30%',
      zIndex: '10000',
    },
  };
  return styles;
};
