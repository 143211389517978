import * as AWS from 'aws-sdk'

const docClient = new AWS.DynamoDB.DocumentClient({
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || 'AKIAYSWFO5ENEFJ3NPW5',
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '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',
      },
    region: 'us-east-1',
    apiVersion: 'latest',
})

export const fetchData = async (tableName) => {
    var params = {
        TableName: tableName
    }
    const promise = docClient.scan(params).promise();
    let result = await promise;
    let data = result.Items;
    return data;
};
